const parseData = ({ allDexJson, allFile }) => allDexJson.nodes.map(mon => ({
    ...mon,
    spriteUrl: allFile.nodes.find(({ name }) => name === mon.name).publicURL
}));

class Pokedex {
    data = [];

    constructor(data) {
        this.data = parseData(data);
    }
}

export default Pokedex;