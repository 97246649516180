import React from "react"
import { Link } from "gatsby"
import Dex from "../components/dex";

import Layout from "../components/layout"

const IndexPage = () => (
	<Layout>
		<Dex />
		<Link to="/page-2/">Go to page 2</Link>
	</Layout>
)

export default IndexPage
