import React from "react";
import PropTypes from "prop-types";
import Pokedex from "../lib/pokedex";
import { useStaticQuery, graphql } from "gatsby";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import { Divider } from '@material-ui/core';
import { navigate } from "gatsby"

import TypeBadge from "./typebadge";
const DexQueryWrapperComponent = () => {
	const data = useStaticQuery(graphql`
			{
				allDexJson(sort: {fields: dexNo___galar}) {
					nodes {
						dexNo {
							galar
							national
						}
						name
						stats {
							total
							spe
							spDef
							spAtk
							hp
							def
							atk
						}
						types
					}
					totalCount
				}
				allFile(filter: {extension: {eq: "png"}, absolutePath: {regex: "^.+?\\/images\\/sprites/"}}) {
					nodes {
						publicURL
						name
					}
				  }
			}
		`)

	const dex = new Pokedex(data);
	return <PokeDexComponent dex={dex} />
}


class PokeDexComponent extends React.Component {

	renderList() {
		return (
			<List>
				{this.props.dex.data.map(mon => <DexEntry {...mon} />)}
			</List>
		);
	}

	render() {
		return (
			<>{this.renderList()}</>
		)
	}
}

const ListItemDivider = () => <Divider orientation="vertical" style={{ height: "35px", margin: "0 1em" }} />;

const DexEntry = props => {
	return (
		<ListItem
			divider
			button
			onClick={() => navigate(`/pokedex/${props.name}`)}
			style={{ flexWrap: "wrap" }}
		>
			<ListItemAvatar>
				<Avatar>
					<img src={props.spriteUrl} />
				</Avatar>
			</ListItemAvatar>
			<ListItemText
				style={{ maxWidth: "41px", minWidth: "41px" }}
				primary={`#${props.dexNo.galar}`}
				secondary={`#${props.dexNo.national}`}
			/>
			<ListItemDivider />
			<ListItemText
				style={{ textTransform: "capitalize", width: "154px", maxWidth: "154px" }}
				primary={props.name}
			/>
			<ListItemDivider />
			<div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}>
				{props.types.map(type => <TypeBadge type={type} />)}
			</div>
			<ListItemDivider />
			<div>
				{/* {Object.keys(props.stats).map(stat => <span>{stat}: {props.stats[stat]}</span>)} */}
			</div>
		</ListItem>
	);
};

PokeDexComponent.propTypes = {
	data: PropTypes.array.isRequired
}

export default DexQueryWrapperComponent;