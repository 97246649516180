import React from "react";
import styled from "styled-components";
import { TypeColors, TypeAbbreviations } from "../constants";

const StyledTypeBadge = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;

	padding: 5px;
    width: 55px;
    height: 24px;

	background-color: ${({ type }) => TypeColors[type]};
	color: white;
    
    font-family: Arial, sans-serif;
	font-size: 12px;
	text-transform: uppercase;
    text-align: center;

    border-radius: 3px;

    &:nth-child(2) {
        margin-top: 8px;
    }
`;
const TypeBadge = ({ type }) => {
    return <StyledTypeBadge type={type}>{TypeAbbreviations[type]}</StyledTypeBadge>;
}

export default TypeBadge;