export const TypeColors = {
    "GRASS": "#7AC74C",
    "NORMAL": "#A8A77A",
    "FIRE": "#EE8130",
    "WATER": "#6390F0",
    "ELECTRIC": "#F7D02C",
    "ICE": "#96D9D6",
    "FIGHTING": "#C22E28",
    "POISON": "#A33EA1",
    "GROUND": "#E2BF65",
    "FLYING": "#A98FF3",
    "PSYCHIC": "#F95587",
    "BUG": "#A6B91A",
    "ROCK": "#B6A136",
    "GHOST": "#735797",
    "DRAGON": "#6F35FC",
    "DARK": "#705746",
    "STEEL": "#B7B7CE",
    "FAIRY": "#D685AD",
};

export const TypeAbbreviations = {
    "GRASS": "GRASS",
    "NORMAL": "NORM",
    "FIRE": "FIRE",
    "WATER": "WATER",
    "ELECTRIC": "ELEC",
    "ICE": "ICE",
    "FIGHTING": "FIGHT",
    "POISON": "POISN",
    "GROUND": "GRND",
    "FLYING": "FLY",
    "PSYCHIC": "PSY",
    "BUG": "BUG",
    "ROCK": "ROCK",
    "GHOST": "GHOST",
    "DRAGON": "DRAG",
    "DARK": "DARK",
    "STEEL": "STL",
    "FAIRY": "FAIRY",
};